import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Typography, Table, Tag, Modal } from "antd";
import { Header, FormatMoney, FormatDate } from "components";
import { useParams } from "react-router-dom";
import { getChargesUser } from "services/user-service";
import { redeemLine } from "services/solicitation-service";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Footer, Content } = Layout;

const DataFetch = {
  data: []
};

const UserChargeScreen = (props) => {
  const { id } = useParams();

  const [data, setData] = React.useState(DataFetch);

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getChargesUser(id);
    setData({ data: res, loading: false });
  };

  React.useEffect(() => {
    load();
  }, [id]);

  const redeemConfirmation = (row) => {
    Modal.confirm({
      title: "Deseja continuar?",
      icon: <InfoCircleOutlined />,
      content: `Após a confirmação, a linha de carga será resgatada.`,
      onOk: async () => {
        await redeem(row);
      },
      onCancel() {}
    });
  };

  const redeem = async (row) => {
    console.log(row);
    setData((x) => ({ ...x, loading: true }));
    await redeemLine({
      solicitation: row.solicitation,
      user: row.user,
      benefit: row.benefit.id
    });
    load();
  };

  return (
    <Layout className="alymente-layout">
      <Header title={"Cargas"} />
      <Content>
        <Table
          scroll={{ y: 800 }}
          dataSource={data.data}
          loading={data.loading}
          pagination={false}
          bordered
        >
          <Table.Column
            width={170}
            align={"center"}
            title="Data"
            dataIndex="createdAt"
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column width={200} title="Nome" dataIndex="name" key="name" />
          <Table.Column
            width={120}
            align={"center"}
            title="Tipo"
            dataIndex={["type", "name"]}
            key="type.name"
          />
          <Table.Column
            width={170}
            align={"center"}
            title="Data de Aprovação"
            dataIndex="approvedAt"
            key="approvedAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column
            width={170}
            align={"center"}
            title="Status do Pedido"
            dataIndex={["status", "name"]}
            key="status.name"
            render={(value, record, index) => (
              <Tag color={record.status.category}>{value.toUpperCase()}</Tag>
            )}
          />
          <Table.Column
            width={170}
            align={"center"}
            title="Benefício"
            dataIndex={["benefit", "name"]}
            key="benefit.name"
            render={(value, record, index) => {
              console.log("record", record);
              return (
                <span>
                  {value}
                  {record.additionalDetails
                    ? " - " + record.additionalDetails
                    : ""}
                </span>
              );
            }}
          />
          <Table.Column
            width={170}
            align={"center"}
            title="Valor"
            dataIndex="value"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={170}
            align={"center"}
            title="Confirmação"
            dataIndex="confirmation"
            key="confirmation"
          />
          <Table.Column
            fixed="right"
            width={120}
            align="center"
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value, row) => (
              <Button
                size="small"
                disabled={
                  row.redeemSolicitation != null ||
                  row.confirmation != "OK" ||
                  row.type.code != "C"
                }
                type={"link"}
                onClick={() => {
                  redeemConfirmation(row);
                }}
              >
                Resgatar
              </Button>
            )}
          />
        </Table>
      </Content>
    </Layout>
  );
};

export default UserChargeScreen;
