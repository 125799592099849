import React from "react";
import { Modal, Typography } from "antd";
import QRCode from "react-qr-code";

export const SolicitationPix = (props) => {
  const { open = false, onClose, pixQRCode } = props;
  return (
    <Modal
      open={open}
      title="PIX de Pagamento"
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={onClose}
      onCancel={onClose}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        {pixQRCode && (
          <React.Fragment>
            <QRCode value={pixQRCode} />
            <Typography.Text style={{ marginTop: 10, fontWeight: 900 }}>
              {pixQRCode}
            </Typography.Text>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};
