import axios from "axios";
import { findPropObject, handlerError } from "utils";
import { notification } from "antd";
import moment from "moment";

export const getAllCompanies = async (filter) => {
  try {
    const res = await axios.get(`/api/company`, { params: { filter } });
    return res.data?.companies ?? [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getCompany = async (company) => {
  try {
    const res = await axios.get(
      `/api/company${company ? "/" + company : "/current"}`
    );
    return res.data.data ? res.data.data : {};
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getCompanyByDocument = async (document) => {
  try {
    const res = await axios.get(`/api/company?cnpj=${document}`);
    return res.data.companies ? res.data.companies : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllChildrenCompanies = async (withParents = true) => {
  try {
    const { data } = await axios.get(`/api/company/children`, {
      params: { withParents: withParents }
    });
    return data.companies || [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllChildrenByCompanyId = async (companyId) => {
  try {
    const { data } = await axios.get(`/api/company/children/${companyId}`);
    return data.companies || [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const search = async (search) => {
  try {
    const res = await axios.get(`/api/company/search`, {
      params: { query: search }
    });
    return res.data.companies;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getTotalBalance = async (company = null, system) => {
  try {
    const { data } = await axios.post(
      `/api/company${company ? "/" + company : ""}/totalBalance`,
      {},
      company ? undefined : { params: { system: system } }
    );
    return data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getSubBalanceByCompanyId = async (companyId) => {
  try {
    const { data } = await axios.get(`/api/company/${companyId}/totalBalance`);
    return data
      ? { subBalances: data.subBalances, companyName: data.companyName }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
export const getStatement = async (system) => {
  try {
    const { data } = await axios.get(`/api/company/statement`, {
      params: { system: system }
    });
    return data.transactions;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getCompanyBenefits = async (allBalance = false) => {
  try {
    const { data } = await axios.get(
      `/api/company/benefit${allBalance ? "?allBalance=true" : ""}`,
      {}
    );
    return data.benefits ? data.benefits : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getPendingCharges = async () => {
  try {
    const { data } = await axios.get(`/api/company/charge`, {
      params: { pending: true }
    });
    return data && data.charges ? data.charges : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getPendingSolicitation = async (company) => {
  try {
    const { data } = await axios.get(
      `/api/company/${company}/solicitation/pending`
    );
    return data && data.data ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllParentManagers = async (id, isChildren = false) => {
  try {
    const res = await axios.get(
      isChildren
        ? `/api/company/parent/manager?matriz=${id}`
        : `/api/company/${id}/parent/manager`
    );
    return res.data ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveParentManagers = async (company, list) => {
  try {
    const req = {
      data: list
    };
    await axios.put(`/api/company/${company}/parent/manager`, req);
    notification.success({
      message: "Salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const getAllParentAdministrator = async (id) => {
  try {
    const res = await axios.get(`/api/company/${id}/parent/administrator`);
    return res.data ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllAdministrators = async (id) => {
  try {
    const res = await axios.get(`/api/company/${id}/parent/administrator`);
    return res.data ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveParentAdministrator = async (company, list) => {
  try {
    const req = {
      data: list
    };
    await axios.put(`/api/company/${company}/parent/administrator`, req);
    notification.success({
      message: "Salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const saveCompany = async (id = null, body) => {
  try {
    body.cnpj = body.cnpj.replace(/\D/g, "");

    const req = {
      data: {
        ...body,
        foundedAt: moment(body.foundedAt).format("YYYY-MM-DD"),
        firstContractAt: body.firstContractAt
          ? moment(body.firstContractAt).format("YYYY-MM-DD")
          : null,
        benefit: findPropObject(body, ["benefit", "id"]) ? body.benefit : null,
        matriz: findPropObject(body, ["matriz", "id"]) ? body.matriz : null,
        partnership: findPropObject(body, ["partnership", "id"])
          ? body.partnership
          : null,
        businessType: findPropObject(body, ["businessType", "code"])
          ? body.businessType
          : null,
        bankAccount: {
          ...body.bankAccount,
          bank: findPropObject(body, ["bankAccount", "bank", "code"])
            ? body.bankAccount.bank
            : null,
          type: findPropObject(body, ["bankAccount", "type", "code"])
            ? body.bankAccount.type
            : null
        }
      }
    };
    let res = null;
    if (id) {
      res = await axios.put(`/api/company/${id}`, req);
    } else {
      res = await axios.post(`/api/company`, req);
    }
    notification.success({
      message: "Dados da empresa salvo com sucesso!"
    });
    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const getCompanyShareholder = async (company) => {
  try {
    const { data } = await axios.get(`/api/company/${company}/shareholders`);
    return data && data.shareholders ? data.shareholders : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveCompanyShareholder = async (id, values, valuesCompany) => {
  if (values.birthday) {
    values.birthday = moment(values.birthday).format("yyyy-MM-DD");
  }
  if (values.foundedAt) {
    values.foundedAt = moment(values.foundedAt).format("yyyy-MM-DD");
  }
  if (values.cpf) {
    values.cpf = values.cpf.replace(/\D/g, "");
  }
  if (values.cnpj) {
    values.cnpj = values.cnpj.replace(/\D/g, "");
  }
  const req = {
    shareholder: values
  };
  let companyId = id;
  let companyData = null;
  if (valuesCompany) {
    companyData = await saveCompany(id, valuesCompany);
  }
  if (!companyId) {
    companyId = companyData.data.id;
  }
  await axios.post(`/api/company/${companyId}/shareholders`, req);
  return companyId;
};

export const updateCompanyShareholder = async (id, values) => {
  if (values.birthday) {
    values.birthday = moment(values.birthday).format("yyyy-MM-DD");
  }
  if (values.foundedAt) {
    values.foundedAt = moment(values.foundedAt).format("yyyy-MM-DD");
  }
  const req = {
    shareholder: values
  };
  await axios.put(`/api/shareholders/${id}`, req);
};

export const deleteCompanyShareholder = async (shareholder) => {
  try {
    await axios.delete(`/api/company/shareholders/${shareholder}`);
  } catch (ex) {
    handlerError(ex);
  }
};

export const getCompanyKycStatus = async (company) => {
  try {
    const { data } = await axios.get(`/api/company/${company}/kycStatus`);
    return data ? data.data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getCompanyActivation = async (company) => {
  try {
    const { data } = await axios.get(`/api/company/${company}/activation`, {});
    return data && data.step !== null ? data.step - 1 : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getInvoiceTransactions = async (date) => {
  try {
    const { data } = await axios.get(
      `/api/company/transactionsInvoice?date=${date}`,
      {}
    );
    return data ? data.data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getBusinessUnits = async (company) => {
  try {
    const { data } = await axios.get(`/api/company/${company}/businessUnits`);
    return data.businessUnits ? data.businessUnits : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
export const getBusinessUnitsEmployeesId = async (company) => {
  const businessUnits = await getBusinessUnits(company);

  if (!businessUnits || businessUnits.length === 0) {
    return [];
  }

  const allEmployeeIds = businessUnits.flatMap((bunit) =>
    Array.isArray(bunit.employees) ? bunit.employees.map((emp) => emp.id) : []
  );

  const uniqueEmployeeIds = [
    ...new Set(allEmployeeIds.map((id) => Number(id)))
  ];

  return uniqueEmployeeIds;
};

export const updateValueTransfer = async (
  fromCompany,
  fromBenefit,
  toCompany,
  toBenefit,
  valueTransfer
) => {
  try {
    const req = {
      fromCompany: fromCompany,
      fromBenefit: fromBenefit,
      toCompany: toCompany,
      toBenefit: toBenefit,
      valueTransfer: valueTransfer
    };
    await axios.post(`/api/company/valueTransfer`, req);
    notification.success({
      message: "Valor Transferido com Sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const postCompanyAccountMigrate = async (companyId, systemAccount) => {
  try {
    await axios.post(`/api/company/${companyId}/migrate`, systemAccount);

    notification.success({
      message: "Solicitação de novo Ambiente realizado com Sucesso!"
    });
  } catch (ex) {
    console.log("Erro na solicitação:", ex.response?.data || ex.message);
    handlerError(ex);
    return null;
  }
};

export const getCompanyAccount = async (companyId) => {
  try {
    const { data } = await axios.get(
      `/api/company/${companyId}/companyAccounts`,
      {}
    );
    return data ? data.companyAccounts : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
