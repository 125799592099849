import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Card,
  Input,
  Select,
  Space,
  Spin,
  Alert,
  notification,
  Button,
  Tooltip,
  Table
} from "antd";
import {
  InputCurrency,
  SwitchLabel,
  InputMask,
  FormAddress,
  AutoCompleteCompany,
  Form,
  FormBankAccount,
  InputNumber,
  Header
} from "components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { COMPANY_TYPE_DATA, BUSINESS_TYPE_DATA } from "utils/static-data";
import {
  getCompany,
  getCompanyBenefits,
  getCompanyKycStatus,
  saveCompany,
  postCompanyAccountMigrate,
  getCompanyAccount
} from "services/company-service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  SaveOutlined,
  UpOutlined,
  DownOutlined,
  PlusOutlined
} from "@ant-design/icons";
import moment from "moment";
import CompanyShareholderScreen from "./company-shareholder-screen";
import { submitForm } from "utils";
import { DatePicker } from "components";

const { Content, Footer } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  config: state.configReducer
});

const CompanyDetailsScreen = React.forwardRef(
  function CompanyDetailsScreen(props, ref) {
    const {
      compact = false,
      company = null,
      onAfterSubmit,
      login,
      config
    } = props;
    const navigate = useNavigate();
    const { id = company } = useParams();
    const [form] = Form.useForm();
    const wtCompanyType = Form.useWatch(["companyType", "code"], form);
    const [data, setData] = React.useState(null);
    const [benefits, setBenefits] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openFantasyName, setOpenFantasyName] = React.useState(false);
    const wtCorporateRefund = Form.useWatch(["corporateRefund"], form);
    const [dataAmbient, setDataAmbient] = React.useState([]);
    const [isButtonDisabled, SetIsButtonDisabled] = React.useState(false);

    const [searchParams] = useSearchParams();
    const load = async () => {
      setLoading(true);
      setBenefits(await getCompanyBenefits());
      if (id && !data) {
        const res = await getCompany(id);
        if (res) {
          const kycStatus = await getCompanyKycStatus(id);
          setData({ ...res, kycStatus: kycStatus ? kycStatus.status : null });
        }
      }
      setLoading(false);
    };

    const loadFieldsCompany = () => {
      form.setFieldsValue({
        ...data,
        foundedAt: data.foundedAt ? moment(data.foundedAt) : undefined,
        firstContractAt: data.firstContractAt
          ? moment(data.firstContractAt)
          : undefined,
        phone:
          data.phone && data.phone.substring(0, 2) === "55"
            ? data.phone.substring(2)
            : data.phone
      });
      setOpenFantasyName(data.hasNickName);
    };

    const onSave = async (values) => {
      setLoading(true);
      try {
        await saveCompany(id, { ...data, ...values });
        if (onAfterSubmit) {
          onAfterSubmit(true);
        } else {
          const params =
            searchParams.size > 0 ? `?${searchParams.toString()}` : "";
          navigate(`/company${params}`);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    const onFinishFailed = () => {
      if (onAfterSubmit) {
        onAfterSubmit();
      }
    };

    const onPostCompanyAccountMigrate = async () => {
      setLoading(true);
      try {
        const hasSwap = dataAmbient.some(
          (record) => record.accountSystem === "swap"
        );
        const hasDock = dataAmbient.some(
          (record) => record.accountSystem === "dock"
        );

        let systemAccount = null;
        if (hasSwap && hasDock) {
          notification.error({
            message: "Ambient Swap e Dock já existem!"
          });
        } else if (!hasSwap) {
          systemAccount = "swap";
        } else if (!hasDock) {
          systemAccount = "dock";
        }

        if (systemAccount) {
          await postCompanyAccountMigrate(id, { systemAccount });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    const createKycStatus = () => {
      if (data) {
        const kycStatus = data.kycStatus;
        if (kycStatus) {
          return (
            <Row align="middle" style={{ marginBottom: 10 }}>
              <Col span={24}>
                <Alert
                  message={`Validação de Dados: ${kycStatus.name}`}
                  type={kycStatus.category}
                  showIcon
                />
              </Col>
            </Row>
          );
        }
      }
      return null;
    };

    const loadSubRoutes = React.useMemo(() => {
      if (id && data && login.company.master) {
        const routes = [
          {
            label: "Vantagens",
            route: `/company/${id}/advantages`,
            state: { company: data }
          }
        ];

        return routes;
      }
      return [];
    }, [id, data, login]);

    React.useImperativeHandle(ref, () => {
      return {
        async submit() {
          return await submitForm(form);
        }
      };
    });

    React.useEffect(() => {
      async function fetchCompanyAccount() {
        if (data) {
          const dataCompanyAccount = await getCompanyAccount(data.id);
          setDataAmbient(dataCompanyAccount);
          loadFieldsCompany();
        }
      }
      fetchCompanyAccount();
    }, [data]);

    React.useEffect(() => {
      if (dataAmbient && dataAmbient.length > 0) {
        const hasSwap = dataAmbient.some(
          (record) => record.accountSystem === "swap"
        );
        const hasDock = dataAmbient.some(
          (record) => record.accountSystem === "dock"
        );
        if (hasSwap && hasDock) {
          SetIsButtonDisabled(true);
        }
      }
    }, [dataAmbient]);

    React.useEffect(() => {
      load();
    }, [id]);

    React.useEffect(() => {
      if (!wtCorporateRefund) {
        form.setFieldsValue({ selectCompanyGroupRefund: false });
      }
    }, [wtCorporateRefund, form]);

    const ContentForm = (
      <Row justify="center">
        <Col xl={12} lg={18} md={24}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onSave}
            onFinishFailed={onFinishFailed}
          >
            <Space direction="vertical" size="large">
              <Card title="Informações Básicas">
                <Spin spinning={loading}>
                  {createKycStatus()}
                  <Form.Item
                    label="Razão Social"
                    hasFeedback
                    name="corporateName"
                    rules={[
                      { required: true, message: "Insira uma razão social" },
                      {
                        pattern: /^(?=.*\p{L})([^!@#$%*]){1,100}$/u,
                        message:
                          "O nome da empresa pode somente conter letras e números, pelo menos uma letra e deve ter no máximo 100 caracteres"
                      }
                    ]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                  <Form.Item noStyle>
                    <Form.Item
                      label="Nome"
                      hasFeedback
                      name="name"
                      rules={[
                        { required: true, message: "Insira um nome" },
                        {
                          pattern: /^(?=.*\p{L})([^!@#$%*]){1,100}$/u,
                          message:
                            "O nome da empresa pode somente conter letras e números, pelo menos uma letra e deve ter no máximo 100 caracteres"
                        }
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        addonAfter={
                          !compact ? (
                            openFantasyName ? (
                              <UpOutlined
                                onClick={() => setOpenFantasyName(false)}
                              />
                            ) : (
                              <DownOutlined
                                onClick={() => setOpenFantasyName(true)}
                              />
                            )
                          ) : null
                        }
                      />
                    </Form.Item>
                    {openFantasyName && (
                      <Form.Item
                        label="Nome Fantasia"
                        hasFeedback
                        name="fantasyName"
                        rules={[
                          { required: true, message: "Insira um nome fantasia" }
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    )}
                  </Form.Item>
                  {!compact && (
                    <Form.Item
                      hasFeedback
                      label="Tipo"
                      initialValue={"C"}
                      name={["companyType", "code"]}
                      rules={[{ required: true, message: "Insira um tipo" }]}
                    >
                      <Select placeholder="Selecione um tipo">
                        {COMPANY_TYPE_DATA.map((x) => (
                          <Select.Option key={x.code} value={x.code}>
                            {x.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    label="CNPJ"
                    hasFeedback
                    name="cnpj"
                    rules={[
                      { required: true, message: "Insira um CNPJ válido" }
                    ]}
                  >
                    <InputMask
                      disabled={id ? true : false}
                      mask="99.999.999/9999-99"
                      placeholder="99.999.999/9999-99"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item label="Gerente de Conta" name="accountManager">
                    <Input autoComplete="off" />
                  </Form.Item>
                  {!compact && (
                    <React.Fragment>
                      {wtCompanyType !== "C" && (
                        <Form.Item label="Taxa" name="fee">
                          <InputNumber addonBefore="%" autoComplete="off" />
                        </Form.Item>
                      )}
                      <Form.Item label="Empresa Matriz" name={["matriz", "id"]}>
                        <AutoCompleteCompany />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: wtCompanyType === "P",
                            message:
                              "Empresas parceiras precisam ser associadas a um benefício principal."
                          }
                        ]}
                        label="Benefício Principal"
                        name={["benefit", "id"]}
                      >
                        <Select placeholder="Selecione um benefício" allowClear>
                          {benefits.map((x) => (
                            <Select.Option key={x.code} value={x.benefit}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </React.Fragment>
                  )}
                  <Form.Item label="Website" name="website">
                    <Input autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Insira um email válido" }
                    ]}
                  >
                    <Input autoComplete="off" type="email" />
                  </Form.Item>
                  <Form.Item
                    label="Tipo de Negócio"
                    name={["businessType", "code"]}
                  >
                    <Select placeholder="Selecione um tipo">
                      {BUSINESS_TYPE_DATA.map((x) => (
                        <Select.Option key={x.code} value={x.code}>
                          {x.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="CNAE" name="cnae">
                    <Input autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="Data de Fundação"
                    name="foundedAt"
                    rules={[
                      { required: true, message: "Insira uma data de fundação" }
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                  <Form.Item label="Telefone" name="phone">
                    <InputMask
                      addonBefore="+55"
                      mask="(99) 99999-9999"
                      placeholder="(00) 00000-0000"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item label="Inscrição Municipal" name="im">
                    <Input autoComplete="off" />
                  </Form.Item>
                  {!compact && (
                    <React.Fragment>
                      {/* <Form.Item
                                            name={"externalAccount"}
                                            initialValue={true}
                                            valuePropName="checked"
                                        >
                                            <SwitchLabel label="CONTA EXTERNA?" />
                                        </Form.Item> */}
                      <Form.Item
                        label="Sistema de Conta"
                        initialValue="swap"
                        name={["accountSystem", "code"]}
                        rules={[
                          {
                            required: true,
                            message: "Insira um sistema de conta"
                          }
                        ]}
                      >
                        <Select
                          disabled={id ? true : false}
                          placeholder="Selecione um sistema de conta"
                          allowClear
                        >
                          {config.system.map((x) => (
                            <Select.Option key={x.code} value={x.code}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={"acceptBill"}
                        initialValue={true}
                        valuePropName="checked"
                      >
                        <SwitchLabel label="PAGAMENTO DE BOLETO?" />
                      </Form.Item>
                      <Form.Item
                        name={"enableCode"}
                        initialValue={false}
                        valuePropName="checked"
                      >
                        <SwitchLabel label="HABILITAR CÓDIGO USUÁRIO?" />
                      </Form.Item>
                      <Form.Item
                        name={"carFleet"}
                        initialValue={false}
                        valuePropName="checked"
                      >
                        <SwitchLabel label="ATIVAR CONTROLE DE FROTAS?" />
                      </Form.Item>
                      {wtCompanyType === "C" && (
                        <Form.Item
                          name={"createInvoiceAuto"}
                          initialValue={true}
                          valuePropName="checked"
                        >
                          <SwitchLabel label="NOTA FISCAL AUTOMÁTICA?" />
                        </Form.Item>
                      )}
                      {wtCompanyType === "C" && (
                        <Form.Item
                          name={"virtualCard"}
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <SwitchLabel label="CARTÃO VIRTUAL?" />
                        </Form.Item>
                      )}
                    </React.Fragment>
                  )}
                </Spin>
              </Card>
              {!compact && (
                <React.Fragment>
                  <Card title="Despesas Corporativas">
                    <Spin spinning={loading}>
                      <Form.Item
                        name={"enabledExpenseImage"}
                        initialValue={true}
                        valuePropName="checked"
                      >
                        <SwitchLabel label="FOTOS DO COMPROVANTE DE GASTOS OBRIGATÓRIA?" />
                      </Form.Item>

                      <Form.Item
                        name={"corporateRefund"}
                        initialValue={true}
                        valuePropName="checked"
                      >
                        <SwitchLabel label="REEMBOLSO CORPORATIVO?" />
                      </Form.Item>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Item
                          name={"selectCompanyGroupRefund"}
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <SwitchLabel
                            label="SELEÇÃO DE EMPRESAS DO GRUPO NO REEMBOLSO CORPORATIVO?"
                            disabled={!wtCorporateRefund}
                          />
                        </Form.Item>
                        <Tooltip
                          title={
                            "Funcionalidade atrelada ao Reembolso Corporativo."
                          }
                        >
                          <InfoCircleOutlined
                            className="color-grey"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginBottom: 25,
                              marginLeft: 8
                            }}
                          />
                        </Tooltip>
                      </div>
                      <Form.Item
                        name={"advance"}
                        initialValue={false}
                        valuePropName="checked"
                      >
                        <SwitchLabel label="ATIVAR ADIANTAMENTO DE GASTOS?" />
                      </Form.Item>
                    </Spin>
                  </Card>
                  <Card title="Contrato">
                    <Spin spinning={loading}>
                      <Row gutter={24}>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Parceiro"
                            name={["partnership", "id"]}
                          >
                            <AutoCompleteCompany />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Primeiro Contrato"
                            name="firstContractAt"
                            rules={[{ required: false }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format={"DD/MM/YYYY"}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Taxa por Reembolso Pessoal"
                            name="refundFee"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Taxa por Colaborador"
                            name="employeeFee"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Taxa de Inatividade"
                            name="inactiveFee"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                        {/* <Col lg={12} sm={24}>
                                    <Form.Item label="Taxa Nota Fiscal" name="taxInvoice">
                                       <InputCurrency autoComplete="off" />
                                    </Form.Item>
                                 </Col> */}
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Taxa de Transferência"
                            name="transferFee"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Valor máximo por transferência"
                            name="transferValue"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Spin>
                  </Card>
                  <Card title="Cartão">
                    <Spin spinning={loading}>
                      <Row gutter={24}>
                        <Col lg={12} sm={24}>
                          <Form.Item label="Custo por cartão" name="cardCost">
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Custo por entrega individual"
                            name="deliveryCost"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Spin>
                  </Card>
                  <Card title="Reembolso Corporativo">
                    <Spin spinning={loading}>
                      <Row gutter={24}>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Custo por reembolso corporativo"
                            name="refundCost"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Custo por boleto"
                            name="refundBillCost"
                          >
                            <InputCurrency autoComplete="off" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Spin>
                  </Card>
                  {id && (
                    <Card title="Ambiente">
                      <Spin spinning={loading}>
                        <Row gutter={24}>
                          <Col lg={12} sm={24} xl={24}>
                            <Table
                              rowKey={(record) => record.key}
                              dataSource={dataAmbient}
                              pagination={false}
                              bordered
                            >
                              <Table.Column
                                width={120}
                                align="center"
                                title="Chave Externa"
                                dataIndex={["externalKey"]}
                                key="externalkey"
                              />
                              <Table.Column
                                width={120}
                                align="center"
                                title="Conta"
                                dataIndex={["accountData", "account"]}
                                key="accountData.account"
                              />
                              <Table.Column
                                width={120}
                                align="center"
                                title="Sistema da Conta"
                                dataIndex={["accountSystem"]}
                                key="accountSystem"
                              />
                              <Table.Column
                                width={120}
                                align="center"
                                title="Status KYC"
                                dataIndex={["kycStatus"]}
                                key="kycStatus"
                              />
                              <Table.Column
                                width={120}
                                align="center"
                                title="Conta de Negócio"
                                dataIndex={["businessAccount"]}
                                key="businessAccount"
                              />
                              <Table.Column
                                width={150}
                                align="center"
                                title="Status"
                                dataIndex="status"
                                key="status"
                              />
                            </Table>
                          </Col>
                        </Row>
                        <Row
                          gutter={24}
                          style={{ alignSelf: "flex-end", marginTop: 10 }}
                        >
                          <Col
                            lg={12}
                            sm={24}
                            xl={24}
                            style={{ textAlign: "end" }}
                          >
                            <Button
                              disabled={isButtonDisabled || loading}
                              loading={loading}
                              className="btn-save-ambient"
                              type="primary"
                              icon={<PlusOutlined />}
                              onClick={onPostCompanyAccountMigrate}
                            >
                              Adicionar Ambiente
                            </Button>
                          </Col>
                        </Row>
                      </Spin>
                    </Card>
                  )}
                  <Card title="Geolocalização">
                    <Spin spinning={loading}>
                      <Row gutter={24}>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Latitude"
                            name={["geolocation", "latitude"]}
                          >
                            <Input autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                          <Form.Item
                            label="Longitude"
                            name={["geolocation", "longitude"]}
                          >
                            <Input autoComplete="off" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Spin>
                  </Card>
                  <FormBankAccount loading={loading} />
                </React.Fragment>
              )}
              <FormAddress loading={loading} form={form} />
            </Space>
          </Form>
        </Col>
      </Row>
    );

    if (compact) {
      return ContentForm;
    } else {
      return (
        <Layout className="alymente-layout">
          <Header
            title={`${data && data.name ? data.name : ""}`}
            routes={loadSubRoutes}
          />
          <Content>
            {ContentForm}
            <Row justify="center" align={"middle"} style={{ marginTop: 30 }}>
              <Col xl={20} lg={22} md={24}>
                <CompanyShareholderScreen
                  loading={loading}
                  company={id}
                  formCompany={form}
                />
              </Col>
            </Row>
          </Content>
          <Footer>
            <Button
              disabled={loading}
              loading={loading}
              className="btn-save"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => form.submit()}
            >
              Salvar
            </Button>
          </Footer>
        </Layout>
      );
    }
  }
);

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  CompanyDetailsScreen
);
